.comment-section {
  /* margin-bottom: 1rem; */
}

.user_comments {
  background-color: var(--color-grey-dark);
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 1rem;
  width: 90%;
  /* flex-wrap: wrap; */
  /* justify-content: space-between; */
  font-size: 1.3rem;
  line-height: 1.5;
  margin-bottom: 1.3rem;
}

.user_comments__incoming {
  background-color: var(--color-primary);
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 1rem;
  width: 80%;
  justify-content: space-between;
  font-size: 1.3rem;
  line-height: 1.5;
  margin-bottom: 1.3rem;
  margin-left: auto;
  color: var(--color-white);
}
.user_comments__imgcontainer {
  width: 2.875em;
  height: 2.875em;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 1rem;
  flex-shrink: 0;
}

.user_comments__imgcontainer img {
  object-fit: cover;
}

.message .form-control::placeholder {
  color: var(--color-grey-darker);
  text-align: left;
  font-size: 1.2rem;
}
.message .form {
  height: 100%;
  display: flex;
  position: relative;
}
.message .form-group {
  flex-grow: 1;
}

.message .form-control {
  color: var(--color-grey-darker);
  text-align: left;
  padding: 1.5rem;
  padding-right: 7.5rem;
  border: none;
  outline: none;
  border-radius: 5rem;
  background-color: var(--color-grey-dark);
  width: 100%;
}

.btn-wrapper {
  background-color: var(--color-primary);
  border-radius: 5rem;
  position: absolute;
  bottom: 0;
  height: 90%;
  margin-bottom: 0.5rem;
  border: 1px solid;
  right: 0;
  width: 80px;
}

.btn-wrapper button {
  width: 80px;

  border-radius: 5rem;
  height: 100%;
  border: none;
  background-color: var(--color-primary);
  color: var(--color-secondary);
}

@media screen and (min-width: 960px) {
  .user_comments__imgcontainer {
    width: 2.875em;
    height: 2.875em;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 2rem;
  }
}
