.container {
  width: 85%;
  margin: auto;
  background-color: white;
  top: 50%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 1rem;
  padding: 1.3rem;
}

.login__logo_container {
  width: 7rem;
}
.logon-section .btn.btn-hero:hover {
  background-color: #155479;
  cursor: pointer;
}

.headertxt {
  font-size: 2.5em;
  font-weight: 700;
  /* width: 80%; */
  text-align: center;
  margin: auto;
  color: var(--color-primary);
}

.login-section .p-txt {
  text-align: center;
  font-size: 13px;
  margin-bottom: 1.2rem;
}

.auth-btn {
  display: flex;
  justify-content: space-around;
  max-width: 200px;
  margin: 0 auto;
}

section.section {
  margin-top: 2em;
}

.btn-google {
  background-color: #ff0000;
  cursor: pointer;
}
.btn-fb {
  background-color: #0093e8;
  cursor: pointer;
}

.icon {
  width: 50px;
  color: white;
  height: 50px;
  padding: 5px 10px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-apple {
  background-color: var(--color-white);
  color: var(--color-dark) !important;
  cursor: pointer;
}

.form-group {
  background-color: var(--color-grey-dark);
  margin-bottom: 1.8rem;
  height: 5rem;
  border-radius: 5rem;
  outline: none;
}
.form-control {
  background-color: var(--color-grey-dark);
  width: 100%;
  height: 100%;
  border-radius: 5rem;
  padding-left: 2rem;
  padding-right: 2rem;
  outline: none;
  border: none;
  text-align: center;
  color: var(--color-primary);
}
.form-control::placeholder {
  color: var(--color-primary);
  text-align: center;
}

.logon-section {
  margin-top: 5rem;
}
.section .account {
  color: inherit;
  margin-top: 0.8rem;
}

@media screen and (min-width: 600px) {
  .container {
    width: 55%;
    padding: 3rem;
  }
}

@media screen and (min-width: 768px) {
  .container {
    width: 48%;
  }
}

@media screen and (min-width: 960px) {
  .container {
    width: 40%;
  }
}

@media screen and (min-width: 1200px) {
  .container {
    /* height: 480px; */
    /* height: 70vh; */

    width: 30%;
    margin: auto;
    border-radius: 1.2rem;
    padding: 4rem;
    background-color: var(--color-white);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 8px 5px 200px rgba(0, 0, 0, 0.5);
  }

  .login-section {
    margin-top: 0em;
  }
  .headertxt {
    margin-top: 2rem;
    margin-bottom: 3.5rem;
    text-align: left;
    font-size: 2rem;
  }
  .p-txt {
    display: none;
  }

  .form-control::placeholder {
    text-align: left;
  }
  .form-control {
    text-align: left;
  }
  .logon-section {
    margin-top: 3rem;
  }
}
