.timeline {
  background-color: var(--color-white);
  padding: 0.8rem;
  border-radius: 0.5rem;
  box-shadow: 0px 10px 10px #0000000d;
  margin-bottom: 2rem;
  position: relative;
  /* display: none; */
}
.active {
  background-color: blue;
}
.timelineContentWrapper {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  /* align-items: center; */
  cursor: pointer;
}
.imgContainer {
  width: 100px;
  height: auto;
  overflow: hidden;
  border-radius: 5px;
  margin-right: 0.9rem;
  flex-shrink: 0;
}

.header__container_wide {
  display: none;
}

.header__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.3rem;
}

.header__container h1 {
  font-size: 1.9rem;
  width: 80%;
  line-height: 1;
  margin: 0;
}
.contentContainer h1 {
  font-size: 1.8rem;
  margin: 0rem;
}

.asset__wrapper {
  background-color: var(--color-grey-dark);
  height: 30px;
  z-index: 1;
  width: 30px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  /* background: rgba(255, 255, 255, 0.3); */
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.active {
  background-color: var(--color-primary);
  height: 30px;
  width: 30px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.asset__wrapper:last-child img {
  width: 100%;
  height: 100%;
}
.contentContainer {
  margin-left: 4rem;
  flex: 1;
}
.contentContainer p {
  font-size: 1.8rem;
  margin-bottom: 0rem;
  font-size: 1.2rem;
  flex: 1;
}

.content__assets {
  display: flex;
  justify-content: space-evenly;
  margin-top: 1.3rem;
}
.content__asset__items:not(:nth-child(4)) {
  display: flex;
  align-items: center;
  border-right: 1px solid var(--color-grey-dark);
  padding-right: 0.6rem;
}
.content__asset__items:not(:nth-child(4)) img {
  width: 15px;
  height: 15px;
}

.content__asset__items:nth-child(4) span {
  display: none;
}

.content__asset__items .asset__wrapper {
  margin-right: 1rem;
}

@media screen and (min-width: 600px) {
  /* .timeline {
    display: none;
  } */
  .timelineContentWrapper {
    flex-direction: column;
    /* align-items: center; */
    justify-content: space-between;
    height: 70% !important;
  }
  .header__container h1 {
    display: none;
  }

  .header__container {
    right: 3.3rem;
    position: absolute;
    top: 2rem;
  }
  .header__container_wide {
    display: block;
  }

  .imgContainer {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 0;
    margin-right: 0.9rem;
  }
  .content__asset__items:nth-child(4) {
    margin-left: auto;
    display: flex;
    align-items: center;
  }
  .content__asset__items:nth-child(4) span {
    font-weight: 800;
    font-size: 1.3rem;
    color: var(--color-primary);
    display: block;
  }
  .asset__wrapper {
    margin-left: 1rem;
  }
  .content__asset__items:nth-child(3) {
    border-right: none;
  }
}
