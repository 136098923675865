:root {
  --color-primary: #10405c;
  --color-secondary: #00f6fb;
  --color-secondary-variant: #2cb7d2;
  --color-white: #fff;
  --color-grey-dark: #efefef;
  --color-grey-darker: #878787;
  --color-dark: #000;
  --color-danger: #ff0000;
}

/* .Toastify__toast {
  background-color: var(--color-primary);
} */
.Toastify__toast .Toastify__toast-theme--light {
  background-color: var(--color-primary) !important;
}

.Toastify__toast-theme--light {
  background-color: var(--color-primary) !important;
  color: var(--color-white) !important;
}
.Toastify__toast-icon svg {
  fill: var(--color-secondary) !important;
}

.Toastify__close-button > svg {
  fill: var(--color-secondary) !important;
}

*,
*:before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

iframe {
  z-index: 0;
  display: none !important;
}

h1,
h2,
h3,
h4 {
  font-weight: 700;
  color: var(--color-dark);
}

h1 {
  font-size: 1.9rem;
  text-transform: capitalize;
}

a {
  text-decoration: none;
  color: var(--color-secondary-variant);
  font-size: 13px;
  text-align: center;
  display: inline-block;
  width: 100%;
}

p {
  text-transform: capitalize;
}

.btn,
.btn:link,
.btn:visited {
  padding: 14px 25px;
  display: inline-block;
  border-radius: 100px;
  text-decoration: none;
  color: var(--color-white);
  border: none;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
}
.btn-alt,
.btn-alt:link,
.btn-alt:visited {
  padding: 14px 25px;
  display: inline-block;
  border-radius: 5px;
  text-decoration: none;
  color: var(--color-white);
  border: none;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
}

.btn-hero {
  background-color: var(--color-primary);
  color: var(--color-secondary);
  width: 100%;
  padding: 20px 25px;
  font-weight: 500;
}
.btn-hero-alt {
  background-color: var(--color-primary);
  color: var(--color-secondary);
  width: 25%;
  padding: 20px 0px;
  font-weight: 500;
}
img {
  width: 100%;
  object-fit: cover;
}
html {
  font-size: 62.5%;
}

body {
  /* height: 100vh; */
  height: 100%;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  line-height: 1.4;
  color: var(--color-dark);
  box-sizing: border-box;
  background-color: var(--color-primary);
  overflow-y: hidden;
}

.app-container {
  background-color: var(--color-grey-dark);
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  
  height: 90%;
  display: flex;
  flex-direction: row;
  position: fixed;
  width: 100%;
  bottom: 0;
  /* overflow: scroll; */
  padding-bottom: 2rem !important;
}

@media screen and (max-width: 599px) {
  .btn-hero-alt {
  
    width: 100%;
    border-radius: 50px;
  }
}

@media screen and (min-width: 600px) {
  .app-container {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    max-width: 80%;
    height: 90vh;
    position: static;
    margin-right: auto;
    margin-left: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .app-container::-webkit-scrollbar {
    display: none;
  }
  body {
    background-color: var(--color-grey-dark);
  }
}

@media screen and (min-width: 1100px) {
  .app-container {
    max-width: 90%;
    height: 90vh;
  }
}

@media screen and (min-width: 1200px) {
  .app-container {
    max-width: 80%;
    gap: 9px;
    height: 90vh;
  }
}

@media screen and (min-width: 2000px) {
  .app-container {
    max-width: 60%;
    height: 90vh;
  }
}
