.header__main {
  display: flex;
}

.container__main {
  align-items: center;
  width: 100%;
  margin: auto;
  padding-bottom: 1rem;
  justify-content: space-between;
}

.logo {
  font-size: 1.4rem;
  font-weight: 800;
  color: var(--color-primary);
  text-align: center;
  margin-bottom: 1.3rem;
  position: absolute;
  left: 50%;
  top: 5%;
  color: var(--color-secondary);
  transform: translate(-50%, -50%);
}

.header__nav {
  justify-content: space-between;
  align-self: flex-end;
  position: absolute;
  z-index: 2;
  bottom: 5rem;
  width: 100%;
  padding: 1rem 0;
}
.header__nav ul {
  list-style: none;
  display: flex;
  justify-content: space-evenly;
  width: 94%;
  margin: auto;
  height: 75px !important;
  background-color: var(--color-primary);
  border-radius: 5rem;
  align-items: center;
}

.accounts_container {
  list-style: none;
  justify-content: space-evenly;
  width: 100px;
  height: 50px !important;
  background-color: transparent;
  border: none;
  align-items: center;
  cursor: pointer;
  align-self: flex-end;
  color: var(--color-white);
  position: absolute;
  top: 2%;

  right: 0;
}

.header__nav ul .nav__item .nav__link {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
}

.header__nav ul .nav__item:nth-child(3) .nav__link {
  background-color: var(--color-secondary);
  border-radius: 50%;
  transform: scale(1.4);
  width: 40px;
  height: 40px;
}

@media screen and (min-width: 600px) {
  .header__main {
    /* height: 5rem; */
    margin-bottom: 2rem;
    display: block;
    width: 100%;
    margin: auto;
    z-index: 1;
    position: relative;
  }
  .timeline_content__assets {
    display: block;
  }
  .container__main {
    align-items: center;
    display: flex;
    width: 84%;
    margin: auto;
    padding-bottom: 1rem;
    justify-content: space-between;
  }

  .logo {
    font-size: 1.4rem;
    font-weight: 800;
    color: var(--color-primary);
    text-align: center;
    margin-top: 20px;
    position: static;
    transform: translate(0%, 0%);

    margin-bottom: 1.3rem;
  }
  .header__nav {
    justify-content: space-between;
    align-self: flex-end;
    position: static;
    z-index: 2;
  }
  .header__nav ul {
    list-style: none;
    display: flex;
    justify-content: space-evenly;
    width: 260px;
    margin: auto;
    height: 60px !important;
    background-color: var(--color-white);
    border-radius: 5rem;
    align-items: center;
  }

  .header__nav ul .nav__item .nav__link {
    background-color: var(--color-grey-dark);
  }

  .header__nav ul .nav__item:nth-child(3) .nav__link {
    background-color: var(--color-grey-dark);

    transform: scale(1);
  }

  .header__nav ul .nav__item .nav__link.active {
    background-color: var(--color-primary);
  }

  .accounts_container {
    list-style: none;
    display: flex;
    justify-content: space-evenly;
    width: 100px;
    height: 50px !important;
    background-color: var(--color-white);
    border-radius: 3rem;
    align-items: center;
    border: none;
    color: var(--color-primary);
    cursor: pointer;
    align-self: flex-end;
    position: static;
  }
}
