.outerContainer {
  padding: 3rem;
  background-color: var(--color-white);
  border-radius: 0.5rem;
  font-size: 1.2rem;
  margin-bottom: 2rem;
}
.img__container {
  border-radius: 50%;
  width: 70px;
  overflow: hidden;
  aspect-ratio: 1;
  margin-bottom: 10px;
}
.header {
  color: var(--color-primary);
  font-size: 1.5rem;
  margin-bottom: 0.7rem;
}
.shares {
  margin-top: 2rem;
}
.social_tags {
  display: flex;
  justify-content: space-between;
  font-size: 1.2rem;
  margin-bottom: 1.2rem;
}
.shared_num {
  font-weight: 700;
}
/* 
@media (min-width: 1200px) {
  .outerContainer {
    padding: 3rem;
    background-color: var(--color-white);
    border-radius: 0.5rem;
    font-size: 1.2rem;
    margin-bottom: 2rem;
    margin: auto;
    width: 90%;
  }
  .img__container {
    margin: auto;
    width: 150px;
  }
  .header {
    width: 50%;
    font-size: 1.9rem;
    margin: auto;
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 0.7rem;
  }

  .outerContainer p {
    width: 50%;
    margin: auto;
    text-align: center;
    font-size: 1.5rem;
  }
  .shares {
    display: none;
  }
} */
