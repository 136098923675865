.post__overview {
  /* height: 100vh; */
  background-color: var(--color-white);
  position: absolute;
  padding-top: 1.7rem;
  width: 100%;
  height: 85vh;
  display: block;
  bottom: -43%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-top-right-radius: 2rem;
  border-top-left-radius: 2rem;

  /* overflow: scroll; */
}
.navigation {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.navigation a {
  color: var(--color-secondary);
  width: 50px;
}
.navigation h2 {
  width: 40%;
  display: inline;
  color: var(--color-secondary);
}

.container__main {
  width: 93%;
  margin: auto;
  padding-bottom: 0.5rem;
  width: 95%;
  overflow: scroll;
  height: 100%;
}

.media {
  display: flex;
  /* justify-content: space-between; */
  width: 100%;
  margin-bottom: 15px;
}
.media-container {
  width: 100px;
  /* height: 12rem; */
  margin-right: 0.8rem;
  border-radius: 5px;
  overflow: hidden;
}
.upload-text {
  margin-bottom: 0.9rem;
}
.number {
  font-size: 1.2rem;
  font-weight: 900;
}

.media-container.add-button {
  background-color: var(--color-grey-dark);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 2;
  cursor: pointer;
  width: 67px;
  height: 100px;
  height: 67px;
  align-self: center;
}
div[disabled] {
  pointer-events: none;
  opacity: 0.7;
}
.add-button-img {
  width: 20px;
  height: 20px;
}

.badges {
  margin-top: 5rem;
}
section.badges h1 {
  font-size: 1.2rem !important;
}
.pills {
  /* display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(3, 300px); */
  display: grid;
  gap: 5px;
  grid-template-columns: repeat(2, fit-content(40%));

  /* width: 100%;
  /* display: flex;
  width: 70%;
  flex-wrap: wrap; */
}
.pills_content {
  width: 50%;
  font-size: 1.3rem;
  margin-bottom: 1.2rem;
  padding: 1.7rem;
  text-align: center;
  background-color: var(--color-grey-dark);
  border-radius: 5rem;
  color: inherit;
  display: block;
}
.pills_content.active {
  background-color: var(--color-primary);
  color: var(--color-white);
}

.post {
  margin-top: 2rem;
}

.form-group-variant {
  margin-bottom: 1rem;
}
.form-label {
  font-size: 1.5rem;
  margin-bottom: 1.2rem;
  display: inline-block;
}
.form-control-variant {
  height: 5rem;
  border-radius: 0.8rem;
  text-align: left;
}
.form-control-variant::placeholder {
  text-align: left;
}
textarea.form-control-variant {
  padding: 1.2rem;
  font-family: "Lato", sans-serif;
  height: 12rem;
}

@media screen and (min-width: 600px) {
  .post__overview {
    padding: 0.7rem;
    width: 80%;
    /* top: 65%; */
    height: 90vh;
    border-radius: 1rem;

    /* overflow: scroll; */
  }
}

@media screen and (min-width: 960px) {
  .post__overview {
    padding: 0.7rem;
    height: 80vh;
    bottom: -33%;
    max-width: 45%;
  }

  .container__main {
    width: 95%;
    overflow: scroll;
    height: 100%;
  }

  .media {
    max-width: 100%;
    min-width: 70%;
    flex-wrap: wrap;
  }
}

@media screen and (min-width: 2000px){
  .post__overview {

  max-width: 25%;
}

}

.loader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid var(--color-primary);
  width: 40px;
  height: 40px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
