.post-details__overview {
  max-height: 90vh;
  background-color: var(--color-primary);
  position: relative;
  width: 100%;
  margin: auto;
  /* margin-top: 2rem; */
  /* margin-bottom: 12rem; */
  top: 7%;
  /* border: 30px solid red; */
  /* overflow: hidden; */
}

/* 



@media screen and (min-width: 959px){
  .post-details__overview{
    top: 20%;
  }
} */

.post-details__container {
  background-color: var(--color-white);
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  height: 87vh;
  position: fixed;
  width: 100%;
  bottom: 0;
  overflow-y: scroll;
  padding-bottom: 1.6rem;
}
.container__main {
  width: 95%;
  margin: auto;
  padding-bottom: 0.5rem;
  overflow: scroll;
  height: 100%;
}
::-webkit-scrollbar {
  display: none;
}

.post-image__container {
  width: 100%;
  height: 400px;
  /* background-color: var(--color-primary); */
  border-radius: 20px;
  margin-top: 2rem;
  /* overflow: hidden; */
}
.post-image__container img {
  width: 100%;
  /* height: 100%; */
  object-fit: cover;
}
h1 {
  margin: 1.7rem 0;
}

.paragraph {
  font-size: 1.4rem;
}
.interact-tab {
  display: flex;
  margin: 2rem 0;
}
.content-gen {
  width: 30px;
  height: 30px;
  border-radius: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content-gen.shine {
  background-color: var(--color-primary);
}
.content-gen.avatar {
  margin-left: auto;
  overflow: hidden;
}

.content-gen.comment {
  margin: 0 0.8rem;
}

.content-gen.comment,
.content-gen.download {
  background-color: var(--color-grey-dark);
}
.liner {
  height: 5px;
  background-color: var(--color-grey-dark);
  width: 100%;
}
@media screen and (min-width: 600px) {
  .post-details__container {
    width: 80%;
    left: 50%;
    height: 90%;
    top: 10%;
    transform: translateX(-50%);
  }

  .post-details__overview {
    top: 5%;
  }
}

@media screen and (min-width: 960px) {
  .post-details__overview {
    position: relative;
    width: 45%;
    background-color: var(--color-primary);
    position: relative;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    max-height: 95vh;
    top: 10%;
    /* overflow-y: scroll; */
  }

  .post-details__container {
    height: 85vh;
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    transform: translateX(0);
    overflow: scroll;
    position: static;
    padding-bottom: 6rem;
  }
}

@media screen and (min-width: 2000px) {
  .post-details__overview {
    width: 30%;
  }
}
