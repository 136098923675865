.timelinelist {
  flex: 50%;
  display: block;
}
.timeline__wrapper {
  width: 100%;
  /* padding-bottom: 7rem; */
}

.date {
  text-align: center;
  margin-bottom: 1.5rem;
}
.date p {
  font-size: 1rem;
  margin-bottom: 0rem;
  margin-top: 1rem;
}
.date h2 {
  color: var(--color-primary);
  font-size: 2rem;
}

@media screen and (min-width: 960px) {
  .timelinelist {
    /* padding-top: 6rem; */
    width: 100%;
  }

  .date p {
    font-size: 1.2rem;
    margin-bottom: 0rem;
  }
}
