.leftSide{
    height: 100%;
    /* min-width: 20%;
    max-width: 25%; */
    flex: 20%;
    display: none;
}

/* @media screen and (min-width: 960px){
    .leftSide{
    display: block;
    padding-top: 2rem;
    }
} */
@media screen and (min-width: 1100px){
    .leftSide{
    max-width: 25%;
    display: block;
    padding-top: 2rem;
    }
}