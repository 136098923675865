.signup.container {
  width: 100%;
  margin: auto;
  overflow: hidden;
  /* height: 90vh; */
}
.headertxt {
  font-size: 2.5em;
  font-weight: 700;
  /* width: 80%; */
  text-align: center;
  margin: auto;
  color: var(--color-primary);
}

.signup-section .p-txt {
  text-align: center;
  font-size: 13px;
  margin-bottom: 1.2rem;
}

.auth-btn {
  display: flex;
  justify-content: space-between;
}
section.section {
  margin-top: 2em;
}

.btn-google {
  background-color: #ff0000;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1;
}
.btn-fb {
  background-color: #0093e8;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1;
}
.icon {
  width: 50px;
  color: white;
  height: 50px;
  padding: 5px 10px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-apple {
  background-color: var(--color-white);
  color: var(--color-dark) !important;
}

.form-group {
  background-color: var(--color-grey-dark);
  margin-bottom: 1.2rem;
  height: 5rem;
  border-radius: 5rem;
  outline: none;
}
.form-control {
  background-color: var(--color-grey-dark);
  width: 100%;
  height: 100%;
  border-radius: 5rem;
  padding-left: 2rem;
  padding-right: 2rem;
  outline: none;
  border: none;
  text-align: center;
  color: var(--color-primary);
}
.form-control::placeholder {
  color: var(--color-primary);
  text-align: center;
}
.tandc {
  font-size: 1.2rem;
  text-align: center;
  display: inline-block;
  width: 100%;
}

.logon-section {
  margin-top: 5rem;
}
.section .account {
  color: inherit;
  margin-top: 0.8rem;
  cursor: pointer;
}

@media screen and (max-width: 360px) {
  .signup.container {
    width: 90%;
    height: 90vh;
  }
}

@media screen and (min-width: 600px) {
  .signup.container {
    width: 55%;
    margin: auto;
    overflow: hidden;
    padding: 3rem;
  }
}
@media screen and (min-width: 960px) {
  .signup.container {
    width: 45%;
    padding-left: 0px;
    padding-right: 0px;
  }

  .inner-container {
    padding: 32px;
  }
}
@media screen and (min-width: 1200px) {
  .signup.container {
    /* height: 90%; */
    width: 35%;
  }

  .signup-section {
    margin-top: 0em;
  }
  .headertxt {
    margin-top: 2rem;
    margin-bottom: 3.5rem;
    text-align: left;
    font-size: 2rem;
  }
  .p-txt {
    display: none;
  }

  .signup .form-control::placeholder {
    text-align: center;
  }
  .signup form .btn.btn-hero:hover {
    cursor: pointer;
  }
  .signup form .btn.btn-hero:hover {
    background-color: #155479;
  }
  .signup .form-control {
    text-align: center;
  }
  .logon-section {
    margin-top: 3rem;
  }
}
