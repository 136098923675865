.pill {
  padding: 0.9rem;
  background-color: var(--color-grey-dark);
  border-radius: 2rem;
  justify-content: space-around;
  margin-right: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  cursor: pointer;
}

.active {
  background-color: var(--color-primary);
}

.active > a {
  background-color: var(--color-primary);
  color: var(--color-secondary);
}

.pill:not(.active) a {
  text-decoration: none;
  display: block;
  color: var(--color-dark);
}
