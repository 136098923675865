.rightSide {
  flex: 25%;
  display: none;
  /* background: var(--color-white); */
  border-radius: 1.2rem;
  height: 100vh;
}

/* @media screen and (min-width: 960px) { */
@media screen and (min-width: 1100px) {
  .rightSide {
    display: block;
    max-width: 25%;

    /* height: 100vh; */
  }

  .sug {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    font-size: 1.2rem;
  }
}
