.filters {
  padding: 3rem 3rem 0.5rem;
  background-color: var(--color-white);
  border-radius: 0.5rem;
  font-size: 1.2rem;
  margin-bottom: 2rem;
}
.filters h3 {
  color: var(--color-primary);
  margin-bottom: 2rem;
}
.pill__container {
  /* display: flex;
  flex-wrap: wrap; */
  display: grid;
  gap: 5px;
  grid-template-columns: repeat(2, fit-content(40%))
}
